import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMobileAlt, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Link} from 'react-router-dom';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import Image from "react-bootstrap/Image";

class ContactComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formSubmitted: false,
            lastSubmittedValues: null,
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        this.inputName = React.createRef();
        this.inputMail = React.createRef();
        this.inputText = React.createRef();
        this.recaptchaRef = React.createRef();
    }


    handleFormSubmit(event) {
        event.preventDefault();
        const recaptchaValue = this.recaptchaRef.current.getValue();

        if (recaptchaValue == null || recaptchaValue === "") {
            return;
        }

        let values = {
            contactName: this.inputName.current.value,
            contactEmail: this.inputMail.current.value,
            contactMessage: this.inputText.current.value,
            'g-recaptcha-response': recaptchaValue
        }

        emailjs.send("service_uuqsba9", "template_7r71hua", values, 'user_HzogeBOq1jxhXRzFwaGM6');

        this.inputName.current.value = "";
        this.inputMail.current.value = "";
        this.inputText.current.value = "";
        this.recaptchaRef.current.reset();

        this.setState(state => ({
            formSubmitted: true,
            lastSubmittedValues: values
        }));
    }

    componentDidMount() {
        if (document.documentElement.scrollTop >= 150) {
            return
        }

        let element = document.getElementById("top");

        window.scroll({
            top: element.offsetTop,
            behavior: "smooth"
        });
    }

    render() {

        if (this.state.formSubmitted) {
            let name = "";
            if (this.state.lastSubmittedValues != null ) {
                name = this.state.lastSubmittedValues.contactName
            }
            return (
                <React.Fragment>

                    <div className="row bg-dark-holz border-holz-gold row mr-0 ml-0">
                        <div className="container">
                            <div className="row">
                                <Breadcrumb>
                                    <BreadcrumbItem active><Link to='/contact'>Kontakt</Link></BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>

                    <span className="anchor" id="top"/>

                    <div className={"container mt-5 pb-5"}>
                        <div className="alert alert-success" role="alert" style={{marginBottom: "5px"}}>
                            <h3 className="alert-heading mt-3">Hallo {name}</h3>
                            <p style={{fontSize: "125%"}}>Vielen Dank! Wir freuen uns sehr über deine Nachricht und
                                werden uns in Kürze bei dir melden. </p>
                            <Image className="mb-3" src={require("../images/trompete_2.png")} style={{height: "80px"}}/>

                        </div>
                        <div className="row">
                            <div className="col text-right">
                                <Link className="holz-link-to-home" to='/home'>zurück zur Startseite</Link>
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div className="row bg-dark-holz border-holz-gold row mr-0 ml-0">
                    <div className="container">
                        <div className="row">
                            <Breadcrumb>
                                <BreadcrumbItem active><Link to='/contact'>Kontakt</Link></BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>

                <span className="anchor" id="top"/>

                <div className={"container mt-5 pb-5"}>
                    <h3>Melde dich bei uns!</h3>
                    <div className={"row"}>
                        <div className={"col"}>
                            {/*
                            <p className="text-center">
                                <em>Sie möchten uns engagieren? Möchten uns womöglich Feedback geben?
                                </em>
                            </p>
                            */}
                            <p>
                                <em>Entweder über das Kontaktformular oder direkt per Telefon oder E-Mail
                                </em>
                            </p>
                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className="col-md-4 text-left">
                            <p><b>Bitte zögere nicht, uns zu kontaktieren:</b></p>
                            <div className={"row mb-1"}>
                                <div className={"col-2 text-center pl-0 pr-0"}><FontAwesomeIcon icon={faMobileAlt}/>
                                </div>
                                <div className={"col pl-0"}>
                                    <a className="comp-contact-link" href="tel:+43 664 2110960">+43 664 2110960</a>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-2 text-center pl-0 pr-0"}><FontAwesomeIcon icon={faEnvelope}/>
                                </div>
                                <div className={"col pl-0"}>
                                    <a className="comp-contact-link"
                                       href="mailto:musi@holzblecherisch.at">musi@holzblecherisch.at</a>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-2 text-center pl-0 pr-0"}><br/></div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-2 text-center pl-0 pr-0"}><span className={"font-weight-bold"}>ZVR:</span> <div/>
                                </div>
                                <div className={"col pl-0"}>
                                    <span>1393284052</span>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-8"}>
                            <form onSubmit={this.handleFormSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <input type="text" className="form-control" id="contact_name"
                                               placeholder="Name" ref={this.inputName} required/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="email" className="form-control" id="contact_mail"
                                               placeholder="E-Mail" ref={this.inputMail} required/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                    <textarea className="form-control" rows="5" id="comment"
                                              placeholder="Nachricht" ref={this.inputText}></textarea>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-6">
                                        <ReCAPTCHA
                                            ref={this.recaptchaRef}
                                            sitekey="6LcQecsZAAAAAFKd1xtyyWvrhFVfdU5UPCsEQsSg"
                                        />
                                    </div>
                                    <div className="col-6 text-right">
                                        <button type="submit" className="btn btn-dark">Senden</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

export default ContactComponent;