import React, {Component} from "react";
import {Image} from "react-bootstrap";


class Header extends Component {

    render() {
        return (
            <Image className="header-image-holz" id="navbar-logo" src={require("../images/temp_background_2.jpg")} fluid/>
        )
    }


}

export default Header;
