import React, {Component} from 'react';

import NavigationBar from "./NavigationBarComponent";
import ContactComponent from "./ContactComponent";
import Footer from "./FooterComponent";
import {Helmet} from 'react-helmet'

import {Switch, Route, Redirect} from 'react-router-dom';
import ImpressumComponent from "./ImpressumComponent";
import DsgvoComponent from "./DsgvoComponent";
import Header from "./Header";
import HomeComponent from "./HomeComponent";

import ReactGA from 'react-ga';
import TimetableComponent from "./TimetableComponent";

class Main extends Component {

    render() {
        ReactGA.initialize('UA-177875145-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        const HomePage = () => {
            return (
                <React.Fragment>
                    <HomeComponent/>
                </React.Fragment>
            );
        };

        return (
            <React.Fragment>
                <Helmet>
                    <title>Holzblecherisch</title>
                    <meta name="description"
                          content="Wir sind eine kleine Musikgruppe aus dem schönen Ennstal, mit Musikerinnen und Musikern aus Schladming, Gröbming, Öblarn, Stein/Enns und Haus im Ennstal.
                          Unser Repertoire erstreckt sich von Polka über Märschen bis hin zu modernen Stücken und wird durch fleißiges Proben laufend erweitert."/>
                </Helmet>
                <div style={{backgroundColor: "#212529"}}>
                    <NavigationBar/>
                    <Header/>
                    <div style={{backgroundColor: "white"}}>
                        <Switch>
                            <Route path="/home" component={() => <HomePage/>}/>
                            <Route path="/contact" component={() => <ContactComponent/>}/>
                            <Route path="/impressum" component={() => <ImpressumComponent/>}/>
                            <Route path="/dsgvo" component={() => <DsgvoComponent/>}/>
                            <Route path="/timetable" component={() => <TimetableComponent/>}/>
                            <Redirect to="/home"/>
                        </Switch>
                        <Footer/>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

export default Main;