import React, {useEffect} from "react";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";

import {DATES} from "../data/dates";
import Timetable from "./Timetable";

// dates={DATES}
function TimetableComponent({dates}) {
    useEffect(() => {
        if (document.documentElement.scrollTop >= 150) {
            return
        }

        let element = document.getElementById("top");

        window.scroll({
            top: element.offsetTop,
            behavior: "smooth"
        });
    }, []);

    return (
        <div>
            <div className="row bg-dark-holz border-holz-gold row mr-0 ml-0">
                <div className="container">
                    <div className="row">
                        <Breadcrumb>
                            <BreadcrumbItem active><Link to='/timetable'>Termine</Link></BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <span className="anchor" id="top"/>
            <div className={"container mt-5 pb-5"}>
                <Timetable dates={DATES}></Timetable>
            </div>
        </div>
    );
}

export default TimetableComponent;