import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";

class ImpressumComponent extends Component {

    render() {

        return (
            <div>
                <div className="row bg-dark-holz border-holz-gold row mr-0 ml-0">
                    <div className="container">
                        <div className="row">
                            <Breadcrumb>
                                <BreadcrumbItem><Link to='/home'>Startseite</Link></BreadcrumbItem>
                                <BreadcrumbItem active><Link to='/impressum'>Impressum</Link></BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>

                <span className="anchor" id="top" />

                <div className="text-left container mt-5 mb-5">

                    <div dangerouslySetInnerHTML={{ __html:
                        "<h2 class=\"adsimple-121407681\">Impressum</h2>\n" +
                            "<p class=\"adsimple-121407681\">Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p>\n" +
                            "<p class=\"adsimple-121407681\">\n" +
                            "<h5>HolzBlecherisch</h5>" +
                            "<p>Kaindorf 51/2<br/>8962 Mitterberg - Sankt Martin<br/>Österreich<br/></p>"+
                            "<p>Tel.: +43 664 2110960<br/>E-Mail: <a href='mailto:musi@holzblecherisch.at'>musi@holzblecherisch.at</a></p>"+
                            "<p>ZVR: 1393284052</p>"+
                            "<p>Obmann: Michael Arnsteiner, BA</p>\n" +
                            "</p>\n" +
                            "</p>\n" +
                            "<h3 id=\"eu-streitschlichtung\" class=\"adsimple-121407681\">EU-Streitschlichtung</h3>\n" +
                            "<p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br />\n" +
                            "Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter <a class=\"adsimple-121407681\" href=\"https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE\" target=\"_blank\" rel=\"noopener noreferrer\">http://ec.europa.eu/odr?tid=121407681</a> zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>\n" +
                            "<p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>\n" +
                            "<h3 id=\"haftung-fuer-inhalte-dieser-webseite\" class=\"adsimple-121407681\">Haftung für Inhalte dieser Webseite</h3>\n" +
                            "<p>Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.</p>\n" +
                            "<p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu kontaktieren, Sie finden die Kontaktdaten im Impressum.</p>\n" +
                            "<h3 id=\"haftung-links-webseite\" class=\"adsimple-121407681\">Haftung für Links auf dieser Webseite</h3>\n" +
                            "<p>Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht laut <a class=\"adsimple-121407681\" href=\"https://www.ris.bka.gv.at/Dokument.wxe?Abfrage=Bundesnormen&Dokumentnummer=NOR40025813&tid=121407681\" target=\"_blank\" rel=\"noopener noreferrer\">§ 17 ECG</a> für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.</p>\n" +
                            "<p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.</p>\n" +
                            "<h3 id=\"urheberrechtshinweis\" class=\"adsimple-121407681\">Urheberrechtshinweis</h3>\n" +
                            "<p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>\n" +
                            "<p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.</p>\n" +
                            "<h3 id=\"bildernachweis\" class=\"adsimple-121407681\">Bildernachweis</h3>\n" +
                            "<p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>\n" +
                            "<br>" +
                            "<p style=\"margin-top:15px\">Quelle: Erstellt mit dem Impressum Generator von <a href=\"https://www.adsimple.at/linkbuilding-backlinkaufbau/\" title=\"AdSimple® Linkbuiling\" rel=\"follow noopener noreferrer\" target=\"_blank\">AdSimple® Linkbuilding</a> in Kooperation mit <a href=\"https://www.aboutbusiness.at/\" target=\"_blank\" rel=\"follow noopener noreferrer\">aboutbusiness.at</a>\n"

                    }} />

                </div>
            </div>
        );
    }


}

export default ImpressumComponent;