export const DATES= [
    {
        id: 1,
        datum: "13.04.2023",
        uhrzeit: "19:00",
        titel: "Öblarn Musikantenroulette",
        active: true
    },
    {
        id: 2,
        datum: "28.05.2023",
        uhrzeit: "10:30",
        titel: "ÖKB Aich",
        active: true
    },
    {
        id: 3,
        datum: "04.06.2023",
        uhrzeit: "10:30",
        titel: "190 Jahre MV Gröbming",
        active: true
    },
    {
        id: 10,
        datum: "25.06.2023",
        uhrzeit: "Frühshoppen",
        titel: "Radio Grün Weiss",
        active: true
    },
    {
        id: 4,
        datum: "13.07.2023",
        uhrzeit: "23:15",
        titel: "Mid Europe",
        active: true
    },
    {
        id: 5,
        datum: "12.08.2023",
        uhrzeit: "Frühshoppen",
        titel: "Harley Treffen Schladming",
        active: true
    },
    {
        id: 6,
        datum: "15.08.2023",
        uhrzeit: "10:00",
        titel: "Pfarrfest Gröbming",
        active: true
    },
    {
        id: 7,
        datum: "18.08.2023",
        uhrzeit: "19:30",
        titel: "Platzkonzert Schladming",
        active: true
    },
    {
        id: 8,
        datum: "09.09.2023",
        uhrzeit: "15:00",
        titel: "Aicher Kirtag",
        active: true
    },
    {
        id: 9,
        datum: "15.10.2023",
        uhrzeit: "9:00",
        titel: "Messe Pfarrkirche Gröbming",
        active: true
    }
];