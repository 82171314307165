import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMobileAlt, faEnvelope} from '@fortawesome/free-solid-svg-icons'

import CookieConsent from "react-cookie-consent";

import {HashLink as Link} from 'react-router-hash-link';


import {
    faFacebook,
    faInstagram
} from '@fortawesome/free-brands-svg-icons'

function Footer(props) {
    return (
        <div className="footer bg-dark-holz text-light pt-5 pb-2 border-holz-gold">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-sm-12 text-left">
                        <h5 className="text-holz-gold">Links</h5>
                        <ul className="list-unstyled footer-links">
                            <li>
                                <Link className="text-light" to="/home">
                                    Startseite
                                </Link>
                            </li>
                            <li>
                                <Link className="text-light" to="/timetable">
                                    Termine
                                </Link>
                            </li>
                            <li>
                                <Link className="text-light" to="/contact">
                                    Kontakt
                                </Link>
                            </li>
                            <li>
                                <Link className="text-light" to="/impressum#top">
                                    Impressum
                                </Link></li>
                            <li>
                                <Link className="text-light" to="/dsgvo#top">
                                    Datenschutz
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4 col-sm-12 text-left">
                        <h5 className="text-holz-gold">Adresse</h5>
                        <address>
                            Kaindorf 51/2<br/>
                            8962 Mitterberg - Sankt Martin<br/>
                        </address>
                        <h5 className="text-holz-gold">Kontakt</h5>
                        <div className={"row mb-1"}>
                            <div className={"col-1 text-left pr-4"}><FontAwesomeIcon icon={faMobileAlt}/></div>
                            <div className={"col pl-0"}>
                                <a className="footer-contact-link" href="tel:+43 664 2110960">+43 664 2110960</a>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-1 text-left pr-4"}><FontAwesomeIcon icon={faEnvelope}/></div>
                            <div className={"col pl-0"}>
                                <a className="footer-contact-link"
                                   href="mailto:musi@holzblecherisch.at">musi@holzblecherisch.at</a>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col"}><br/></div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-1 text-left pr-5"}>ZVR:</div>
                            <div className={"col pl-0"}>
                                1393284052
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right footer-social-media">
                            <h5 className="text-holz-gold">Social Media</h5>
                            <a className="btn btn-social-icon btn-facebook text-light footer-social-media"
                               href="https://www.facebook.com/holzblecherisch"
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon size="2x" icon={faFacebook}/>
                            </a>
                            <a className="btn btn-social-icon btn-facebook text-light footer-social-media"
                               href="https://www.instagram.com/holzblecherisch/"
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon size="2x" icon={faInstagram}/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-end mt-3">
                    <div className="col-auto">
                        <p>© Copyright 2023 HolzBlecherisch</p>
                    </div>
                </div>

                <CookieConsent location="bottom" buttonText="OK" cookieName="holzCookieConsent"
                               expires={999}>
                    Diese Seite verwendet Cookies. Diese dienen dazu, die Funktionalität dieser Website zu
                    gewährleisten. Nähere Informationen finden Sie unter
                    <Link className="holz-cookie-link" to="/dsgvo">
                        <span> Datenschutz</span>
                    </Link>.
                </CookieConsent>
            </div>
        </div>
    );
}

export default Footer;