import React, {Component} from 'react';

import {Navbar, Nav, Image} from 'react-bootstrap'
import {NavLink} from 'react-router-dom';

class NavigationBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageYOffset: 0,
            expand: true,
            logoHeight: null,
            diff: 0
        };
    }


    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    };


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    };


    handleScroll = (event) => {

        if (document.documentElement.scrollTop >= 5) {
            document.getElementById("navbar-row").style.opacity = "0.90";
        } else {
            document.getElementById("navbar-row").style.opacity = "1";
        }

    }


    render() {
        return (
            <React.Fragment>

                <Navbar id="navbar-row" className="border-holz-gold bg-dark-holz" sticky="top" collapseOnSelect
                        expand="lg" bg="dark" variant="dark">
                    <div className="container font-weight-bold">
                        <div className="row align-items-center">

                            <div className="col col-xs-6 col-md-6 text-left">
                                <Navbar.Brand href="/">
                                    <Image id="navbar-logo" src={require("../images/logo_white_op_klein.png")} fluid
                                           style={{"width": "50%"}}/>
                                </Navbar.Brand>
                            </div>

                            <div className="col col-xs-6 col-md-6 text-right ">
                                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                                <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className="ml-auto">
                                        <NavLink className="nav-link text-holz-gold" to="/home">
                                            Startseite
                                        </NavLink>
                                        <NavLink className="nav-link text-holz-gold" to="/timetable">
                                            Termine
                                        </NavLink>
                                        <NavLink className="nav-link text-holz-gold" to="/contact">
                                            Kontakt
                                        </NavLink>
                                    </Nav>
                                </Navbar.Collapse>
                            </div>
                        </div>

                    </div>
                </Navbar>

            </React.Fragment>
        );
    }

}

export default NavigationBar;