import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";
import {Image} from "react-bootstrap";

import {MUSICIANS} from "../data/musicians";
import Musician from "./MusicanComponent";


class HomeComponent extends Component {

    render() {
        let musicians = MUSICIANS.map((musician) => <Musician key={musician.id} data={musician}/>);

        return (
            <React.Fragment>
                <div className="row bg-dark-holz border-holz-gold row mr-0 ml-0">
                    <div className="container">
                        <div className="row">
                            <Breadcrumb>
                                <BreadcrumbItem active><Link to='/home'>Startseite</Link></BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                <div className={"container mt-5 pb-5"}>
                    <div className="row justify-content-center">
                        <div className="col-8 col-md-4">
                            <Image className="header-image-holz" id="navbar-logo"
                                   src={require("../images/logo_black.png")} fluid/>
                        </div>
                    </div>

                    <h5><cite>Wenn die Krise auch Musikalisches bringt</cite></h5>

                    <div className="row mt-4">
                        <div className="col">
                            <p>
                                Nach der coronabedingten Zwangspause in den Musikvereinen war auch nach dem Lockdown das
                                Proben
                                in der Gruppe leider für lange Zeit nur im kleinem Rahmen oder im privaten Bereich
                                möglich.
                                Des Wartens überdrüssig trafen sich einige von uns im Garten eines Mitglieds,
                                und die Idee für HolzBlecherisch ist entstanden.
                            </p>
                            <p>
                                Nach ein paar Proben und der einen oder anderen Kiste Bier stand unserem ersten Auftritt
                                bei der
                                „1. Mitterberger Wirtshausroas“ im August 2020 nichts mehr im Wege.

                                Unser Repertoire erstreckt sich
                                von <strong>Polka</strong> über <strong>Märschen</strong> bis hin
                                zu <strong>modernen Stücken</strong> und wird durch fleißiges Proben laufend erweitert.
                            </p>
                        </div>
                    </div>

                    <h3 className="mt-4">Das Holz und das Blech</h3>
                    <div className="row mt-4">
                        <div className="col">
                            <p>
                                Wir sind alle Musikerinnen und Musiker zwischen 20 und 30 Jahren und kommen aus den
                                Musikkapellen Gröbming, Öblarn, Stein/Enns, Haus im Ennstal und der Stadtkapelle Schladming.
                            </p>
                        </div>
                    </div>

                    <div id="musicians" className="row mt-4">
                        {musicians}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default HomeComponent;