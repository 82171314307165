import React, {Component} from 'react';
import Image from 'react-bootstrap/Image'

import moment from 'moment';
import 'moment/locale/de';

class Musician extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }


    handleClick(event) {
        event.preventDefault();
        /*
        event.currentTarget.closest('div.image-flip').classList.toggle('hover');
        */
    }


    renderFrontendText = () => this.props.data.mainInstrument;

    render() {
        if (this.props.data === undefined) {
            return (<div></div>)
        }

        let musician = this.props.data;
        let img = require("../images/no_image_icon.png");
        if (musician.image !== null) {
            img = require("../images/musicians/" + musician.image);
        }
        let instrument_img = require("../images/instrumente/" + musician.instrument)

        let bg_img = require("../images/background.png");
        return (
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" hidden={this.props.hidden}>
                <div className="image-flip">
                    <div className="mainflip flip-0">

                        <div className="frontside">
                            <div className="card bg-light card-border-holz" onClick={this.handleClick}>
                                <Image className="card-img" src={img}/>
                            </div>
                        </div>

                        <div className="backside">
                            <div className="card card-border-holz" onClick={this.handleClick}>
                                <Image className="card-img" src={bg_img}/>
                                <div className="card-body text-center mt-4"
                                     style={{position: "absolute", left: "0", right: "0"}}>
                                    <h4 className="card-title">{musician.firstname} {musician.lastname}</h4>
                                    <h5 className="card-sub-title">
                                        {musician.mainInstrument}
                                    </h5>
                                    <p>
                                        Mitglied seit {moment(musician.since).locale('de').format('MMMM YYYY')}
                                    </p>
                                    <p>
                                        Spielt {musician.chapel}
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Musician;
