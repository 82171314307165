import React, {useEffect, useState} from "react";
import axios from 'axios';


function Timetable({dates}) {
    const [data, setData] = useState([]);

    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        let access_token = "92aa28656a041c1b782c2350ddd5b29d4301c6b01be8a196f3e3a9dad398dd2f05eaec814efdb620430ca9297cbe6647aca395fb5eca824e04e052fceda84a76fe316e6a4a51942c83e9178193193ab5bb26a5fc6824cb730bf894c67d92858abf003809b7c049bd81b798211d3120d9b5eb9e41f7e72beee699ae70bf7e3ed4";
        axios.get(
            'https://api.holzblecherisch.at/api/termines?sort=Datum:asc&filters[Datum][$gte]=' + getCurrentDate(),
            {
                headers: {
                    Authorization: `Bearer ` + access_token,
                }
            })
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
    }

    function formatTime(timeString) {
        const date = new Date(`2000-01-01T${timeString}`);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    }

    return (
        <div>
            {Array.isArray(data) ?
                (<p>Loading ... </p>)
                : (
                    <table className="table">
                        <thead className="thead-dark text-center">
                        <tr>
                            <th className="align-middle border">Datum</th>
                            <th className="align-middle border">Uhrzeit</th>
                            <th className="align-middle border">Titel</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.data.map((date, index) => (
                            <tr key={date.id} className={index % 2 === 0 ? "bg-light" : ""}>
                                <td className="border-0">{formatDate(date.attributes.Datum)}</td>
                                <td className="border-0">{formatTime(date.attributes.Uhrzeit)}</td>
                                <td className="border-0 text-left">{date.attributes.Beschreibung}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>)
            }
        </div>

    );
}

export default Timetable;