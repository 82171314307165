export const MUSICIANS = [
    /*
    {
        id: 0,
        firstname: "Conny",
        lastname: "Höflehner",
        since: "2020-08-01",
        chapel: "bei der Musikkapelle Stein an der Enns",
        image: "Conny.jpg",
        mainInstrument: "Querflöte",
        instrument: "querfloete.png"
    },*/
    {
        id: 1,
        firstname: "Sophie",
        lastname: "Lipp",
        since: "2024-05-19",
        chapel: "beim Musikverein Trachtenkapelle Gröbming",
        image: null,
        mainInstrument: "Klarinette",
        instrument: "klarinette.png"
    },
    {
        id: 2,
        firstname: "Verena",
        lastname: "Lassacher",
        since: "2020-08-01",
        chapel: "beim Musikverein Öblarn",
        image: "Verena.jpg",
        mainInstrument: "Klarinette",
        instrument: "klarinette.png"
    },
    {
        id: 16,
        firstname: "Walter",
        lastname: "Ladreiter",
        since: "2020-08-01",
        chapel: "bei der Musikkapelle Stein an der Enns",
        image: "Walter.jpg",
        mainInstrument: "Klarinette",
        instrument: "klarinette.png"
    },
    {
        id: 3,
        firstname: "Roland",
        lastname: "Mali",
        since: "2020-08-01",
        chapel: "beim Musikverein Öblarn",
        image: null,
        mainInstrument: "Flügelhorn",
        instrument: "trompete.png"
    },
    {
        id: 4,
        firstname: "Stefan",
        lastname: "Lutzmann",
        since: "2024-03-01",
        chapel: "beim Musikverein Trachtenkapelle Gröbming",
        image: null,
        mainInstrument: "Flügelhorn",
        instrument: "trompete.png"
    },
    {
        id: 15,
        firstname: "Jonas",
        lastname: "Lassacher",
        since: "2022-01-01",
        chapel: "beim Musikverein Öblarn",
        image: "Jonas.jpg",
        mainInstrument: "Flügelhorn",
        instrument: "trompete.png"
    },
    {
        id: 7,
        firstname: "Lukas",
        lastname: "Arnsteiner",
        since: "2020-08-01",
        chapel: "beim Musikverein Trachtenkapelle Gröbming",
        image: "Lukas.jpg",
        mainInstrument: "Flügelhorn",
        instrument: "trompete.png"
    },
    {
        id: 5,
        firstname: "Michael",
        lastname: "Stadlmann",
        since: "2020-08-01",
        chapel: "beim Musikverein Trachtenkapelle Gröbming",
        image: "Michael_St.jpg",
        mainInstrument: "Trompete",
        instrument: "trompete.png"
    },
    {
        id: 8,
        firstname: "Andreas",
        lastname: "Krug",
        since: "2020-08-01",
        chapel: "bei der Stadtkapelle Schladming",
        image: "Andreas.jpg",
        mainInstrument: "Tenorhorn",
        instrument: "tenorhorn.png"
    },
    {
        id: 9,
        firstname: "Marcel",
        lastname: "Perhab",
        since: "2020-08-01",
        chapel: "bei der Musikkapelle Stein an der Enns",
        image: "Marcel.jpg",
        mainInstrument: "Tenorhorn",
        instrument: "tenorhorn.png"
    },
    {
        id: 10,
        firstname: "Florian",
        lastname: "Berger",
        since: "2022-01-01",
        chapel: "beim Musikverein Trachtenkapelle Gröbming",
        image: "Florian.jpg",
        mainInstrument: "Bariton",
        instrument: "tenorhorn.png"
    },
    {
        id: 11,
        firstname: "Johannes",
        lastname: "Ladreiter",
        since: "2020-08-01",
        chapel: "bei der Musikkapelle Stein an der Enns",
        image: "Johannes.jpg",
        mainInstrument: "Horn",
        instrument: "tenorhorn.png"
    },
    {
        id: 12,
        firstname: "Moritz",
        lastname: "Redl",
        since: "2024-03-01",
        chapel: "beim Musikverein Trachtenkapelle Gröbming",
        image: null,
        mainInstrument: "Posaune",
        instrument: "tenorhorn.png"
    },
    {
        id: 13,
        firstname: "Niklas",
        lastname: "Lipp",
        since: "2024-03-01",
        chapel: "beim Musikverein Trachtenkapelle Gröbming",
        image: null,
        mainInstrument: "Bass",
        instrument: "tenorhorn.png"
    },
    {
        id: 14,
        firstname: "Michael",
        lastname: "Arnsteiner",
        since: "2020-08-01",
        chapel: "beim Musikverein Trachtenkapelle Gröbming",
        image: "Michael.jpg",
        mainInstrument: "Schlagzeug",
        instrument: "tenorhorn.png"
    },
];
